<template lang="pug">
// Ideally for a11y, dialogs should attach to the button that triggered them.
// But this is not possible in the header, given Vuetify's CSS.
// We attach to #main (rather than #app) because of
// https://dequeuniversity.com/rules/axe/3.5/region
div.the-bible-verse-picker.d-flex.align-center.justify-space-between
  VersePickerDialog(
    v-model:range="displayedRange"
    v-model:open="versePickerDialogOpen"
    :verse-picker-tid="versePickerTid"
    :local-tid="localTid"
    :history="userRecentStore.scripture"
  )
    template(#activator="activatorProps")
      VBtn.the-bible-verse-picker__verses-button(
        variant="text"
        v-bind="activatorProps['props']"
        title="Change verses"
        aria-label="Change verses"
      )
        | {{ humanReadableRange.error ? "Error" : (humanReadableRange.value ?? "…") }}
        VIcon.ml-2(
          v-if="humanReadableRange.error"
          :title="humanReadableRange.error.message"
        ) {{ mdiAlert }}

  VersePickerTranslationDialog(
    v-model:tids="translationDialogModel"
    v-model:open="versePickerTranslationDialogOpen"
    :available-translations="allTranslationsArray"
    :multiple="true"
    :required="true"
  )
    template(#activator="activatorProps")
      VBtn.the-bible-verse-picker__translation-button(
        variant="text"
        v-bind="activatorProps['props']"
        title="Change translation"
        aria-label="Change translation"
      ) {{ openTranslationsAbbreviations }}
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { mdiAlert } from "@mdi/js";
import { useBibleStore } from "~/stores/useBibleStore";
import { useUserRecentStore } from "~/stores/useUserRecentStore";
import { injectRequired } from "~/injectRequired";
import { useComputedResultAsync } from "~/composables/useComputedResultAsync";
import { allTranslations, allTranslationsArray } from "~/static/translations";
import { ScriptureClientKey } from "~/injectionKeys";
import { localesByCode } from "~/i18n/locales";
import { never } from "~/never";

const scriptureClient = injectRequired(ScriptureClientKey);

const bibleStore = useBibleStore();
const runtimeConfig = useRuntimeConfig();

const i18n = useI18n();
const localeConfig = computed(
  () => localesByCode.get(i18n.locale.value) ?? never("Locale not found."),
);

const userRecentStore = useUserRecentStore();

const versePickerDialogOpen = ref<boolean>(false);

const versePickerTranslationDialogOpen = ref<boolean>(false);

interface Props {
  /**
   * The ID of the user's preferred local translation.
   */
  localTid: string;
}

const props = defineProps<Props>();

/**
 * The ID of the translation to use in the verse picker.
 */
const versePickerTid = computed<string>(() => {
  if (translationDialogModel.value.length === 1) {
    // Exactly one translation is open, so use that translation in the verse picker.
    return translationDialogModel.value[0] ?? props.localTid;
  }

  // More than one translation is open, so use the user's preferred local translation in the verse picker.
  return props.localTid;
});

const translationDialogModel = computed<string[]>({
  get() {
    return bibleStore.getOpenTranslations(localeConfig.value.defaultTid);
  },
  set(value) {
    bibleStore.setOpenTranslations(value);
  },
});

const openTranslationsAbbreviations = computed<string>(() =>
  bibleStore
    .getOpenTranslations(localeConfig.value.defaultTid)
    .map((tid) => allTranslations[tid]?.abbreviation)
    .join(", "),
);

const displayedRange = computed({
  get: () =>
    bibleStore.getDisplayedRange(runtimeConfig.public.bible.defaultVids),
  set: (value) => bibleStore.setDisplayedRange(value),
});

/**
 * A human-readable version of the displayed VID range.
 */
const humanReadableRange = useComputedResultAsync(() =>
  displayedRange.value
    .changeTranslation(versePickerTid.value)
    .renderReference(scriptureClient, props.localTid, false, false),
);
</script>
