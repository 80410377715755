<template lang="pug">
TheAppBar
  VContainer.pa-0
    h1.d-sr-only {{ title }}
    TheBibleVersePicker(:local-tid="localTid")
</template>

<script setup lang="ts">
import { computed } from "vue";
import { allTranslations } from "~/static/translations";
import { useBibleStore } from "~/stores/useBibleStore";
import { localesByCode } from "~/i18n/locales";
import { never } from "~/never";

const i18n = useI18n();
const localeConfig = computed(
  () => localesByCode.get(i18n.locale.value) ?? never("Locale not found."),
);
const localTid = computed<string>(() => localeConfig.value.defaultTid);

const bibleStore = useBibleStore();

const title = computed<string>(() => {
  const openTranslationsNames = bibleStore
    .getOpenTranslations(localeConfig.value.defaultTid)
    .map((tid) => allTranslations[tid]?.name)
    .join(", ");

  return `Bible: ${openTranslationsNames}`;
});
</script>

<style scoped lang="scss">
.v-toolbar,
.the-bible-verse-picker,
.the-bible-translation-header {
  width: 100%;
}
</style>
