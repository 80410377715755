<template lang="pug">
VDialog.verse-picker-translation-dialog(
  v-model="openModel"
  v-bind="$attrs"
  :fullscreen="fullScreen"
  :scrollable="true"
  :persistent="true"
  :max-width="fullScreen ? undefined : 500"
  @click:outside="cancel"
)
  template(#activator="activatorProps")
    slot(
      name="activator"
      v-bind="activatorProps"
    )

  VCard
    VCardTitle
      VBtn.mr-2.verse-picker-translation-dialog__cancel-button(
        :icon="true"
        :title="i18n.t('Global.Cancel')"
        :aria-label="i18n.t('Global.Cancel')"
        size="small"
        variant="flat"
        @click="cancel"
      )
        VIcon {{ mdiArrowLeft }}
      span {{ i18n.t("VersePicker.ChooseTranslations") }}

    VDivider

    VCardText(style="height: 100%")
      VList
        VListItem(
          v-for="translation in availableTranslations"
          :key="translation.tid"
          :active="tidsModel.includes(translation.tid)"
          @click="clickListItem(translation.tid)"
        )
          template(#default)
            VListItemTitle {{ translation.name }}
            VListItemSubtitle {{ translation.abbreviation }}

          template(
            v-if="multiple"
            #append="{ isActive }"
          )
            VListItemAction
              VCheckbox(
                :model-value="isActive"
                color="primary"
                density="compact"
              )

    template(v-if="multiple")
      VDivider
      VCardActions
        VSpacer
        VBtn.verse-picker-translation-dialog__ok-button(
          :icon="true"
          :title="i18n.t('Global.OK')"
          :aria-label="i18n.t('Global.OK')"
          size="x-large"
          @click="ok"
        )
          VIcon {{ mdiCheck }}
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { mdiArrowLeft, mdiCheck } from "@mdi/js";
import type { StandaloneTranslationData } from "@rsc/scripture-model";
import { useDisplay } from "vuetify";

export interface Props {
  /**
   * The selected translations.
   */
  tids: string[];

  /**
   * If multiple is true, the user may select more than one translation.
   */
  multiple?: boolean;

  /**
   * If required is true, the user must select at least one translation.
   */
  required?: boolean;

  /**
   * The translations available to the user.
   */
  availableTranslations: StandaloneTranslationData[];

  /**
   * Whether the dialog is open.
   */
  open?: boolean;
}

const i18n = useI18n();

const props = withDefaults(defineProps<Props>(), {
  tids: () => [],
});

const emit = defineEmits<{
  "update:tids": [value: string[]];
  "update:open": [value: boolean];
}>();

const display = useDisplay();

const fullScreen = computed<boolean>(() => display.xs.value);

/**
 * Model for the dialog's opened/closed state.
 */
const openModel = computed<boolean>({
  get() {
    return props.open;
  },
  set(value) {
    emit("update:open", value);
  },
});

/**
 * Model for the selected translation(s).
 * Using `ref` instead of `WritableComputed` because we only emit when closing the dialog.
 */
const tidsModel = ref<string[]>(props.tids || []);

function cancel() {
  tidsModel.value = props.tids;
  openModel.value = false;
}

function ok() {
  if (props.required && tidsModel.value.length === 0) {
    // At least one translation must be selected.
    return;
  }

  emit("update:tids", tidsModel.value);
  openModel.value = false;
}

/**
 * Respond to a translation being clicked / tapped by the user.
 *
 * @param tid
 *   The ID of the clicked / tapped translation.
 */
function clickListItem(tid: string) {
  if (props.multiple) {
    if (tidsModel.value.includes(tid)) {
      // Remove this translation from the selection.
      tidsModel.value = tidsModel.value.filter((t) => t !== tid);
    } else {
      // Add this translation to the selection.
      tidsModel.value.push(tid);
    }
  } else {
    // Only one selected translation allowed at a time.
    tidsModel.value = [tid];
    ok();
  }
}
</script>
